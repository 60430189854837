import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { SECTIONS_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { ROUTE_PATHS, URLS } from "../../constants";
import { Link } from "../../containers";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

export default function GoNoodleHeader({ className }) {
  const router = useRouter();
  return (
    <nav className={`gn-nav ${className}`}>
      <div className="flex items-center justify-center h-navbar">
        <div className="transform transition hover:scale-110">
          <Link
            href={ROUTE_PATHS.HOME}
            events={[
              {
                event: "Link Clicked",
                properties: {
                  title: "GoNoodle Logo",
                  sourceElement: SECTIONS_TYPES.NAV,
                  sourceName: SECTIONS_TYPES.NAV,
                  ...getUtmParamsFromQueryParams(router.query),
                },
              },
            ]}
          >
            <span className="sr-only">GoNoodle</span>
            <Image
              width={150}
              height={25}
              src={`${URLS.GN_ASSETS_BASE_URL}/web_public/GoNoodleLogo_Registered_Reverse.svg`}
              alt="GoNoodle logo"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}
